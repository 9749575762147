import React, { useState, useEffect } from "react";

import { fetchData } from '../utilities/async';
import LayoutHeader from '../Layout/LayoutHeader';
import Loader from '../components/Loader';
import TagFigure from '../components/TagFigure';
import ItemControls from '../components/ItemControls';
import VideoItem from '../components/VideoItem';

const Videos = (props) => {
  // data
  const { REACT_APP_CDN } = process.env;
  const endpoint = '/json/kidkrazydotnet-video.json';
  //pathing
  const imagePath = REACT_APP_CDN + '/_images/thumbs/video/';
  const imagePathFull = REACT_APP_CDN + '/images/video/';
  const imagePathHero = REACT_APP_CDN + '/images/hero/video/';
  const videoPath = REACT_APP_CDN + '/videos/';
  // state
  // const [loading, setLoading] = useState(true);
  const [dataUrl, setDataUrl] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [selectedProject, setSelectedProject] = useState(0);
  const [poster, setPoster] = useState('');
  const [details, setDetails] = useState(false);
  const [viewList, setViewList] = useState(true);
  const currentView = dataUrl[selectedProject];

  // Run once on first render
  useEffect(() => {
    window.scrollTo(0, 0);

    fetchData(endpoint).then((ActualData) => {
      setDataUrl(ActualData.data);
      setError(null);
      // console.log('data', ActualData.data)
    })
      .catch((err) => {
        setError(err.message);
        setDataUrl(null);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [endpoint]);

  const detailOpen = (index) => {
    setDetails(true);
    setSelectedProject(index);
  }

  const detailClose = (children) => {
    setDetails(!setDetails);
  }

  const detailPrev = (children) => {

    if (selectedProject === 0) {
      detailClose();
    }

    setSelectedProject(selectedProject - 1);
    window.scrollTo(0, 0);
  }

  const detailNext = (children) => {
    const projectTotal = dataUrl.length;

    if (selectedProject === projectTotal - 1) {
      detailClose();
    }

    setSelectedProject(selectedProject + 1);
    window.scrollTo(0, 0);
  }

  // sort by date (desc) and prioritize featured
  let sorted = dataUrl.sort(
    function (a, b) {
      if (a.featured === b.featured) {
        // featured is only important when date is the same
        return b.date - a.date;
      }
      return a.featured > b.featured ? -1 : 1;
    });

  const ProjectList = sorted.map((item, index) => {
    return (
      <TagFigure
        key={index}
        tabIndex="0"
        className={'video__thumb' + `${item.featured ? ' featured' : ''}`}
        src={item.featured ? imagePath + item.thumbnail : imagePath + item.thumbnail}
        onClick={(e) => detailOpen(index, e)}
        caption={item.title}
      />
    )
  })

  if (loading) return <Loader />;

  if (error || !dataUrl) {
    return <p>There was an error loading your data!</p>;
  }

  if (details && dataUrl.length > 0) {
    return (
      <>
        <VideoItem
          key={selectedProject}
          className='projects__detail'
          title={currentView.title}
          summary={currentView.summary}
          summaryFull={currentView.summaryFull}
          role={currentView.role}
          keywords={currentView.keywords}
          date={currentView.date}
          runtime={currentView.runtime}
          poster={currentView.poster !== null ? imagePath + currentView.poster : 'placeholder'}
          source={currentView.file !== null ? videoPath + currentView.file : ''}
          src={currentView.link !== null ? currentView.link : ''}
          type={currentView.srcType !== null ? currentView.srcType : ''}
        />
        <ItemControls
          className='projects__controls '
          close={() => detailClose()}
          next={() => detailNext()}
          previous={() => detailPrev()}
        />
      </>
    )
  }

  return (
    <>
      <LayoutHeader data={props.header} />
      <div className={viewList ? 'projects--grid' : 'projects--list'}>
        {[ProjectList]}
      </div>
    </>
  );
};

export default Videos;