import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkUnwrapImages from 'remark-unwrap-images'

import { fetchMd } from '../utilities/async';
import LayoutHeader from '../Layout/LayoutHeader';
import Loader from '../components/Loader';
import TagFigure from "../components/TagFigure";
import GallerySingle from '../components/GallerySingle';

const SecureUiux = (props) => {
  // data
  const endpoint = '/_sec-uiux/uiux.md';
  //pathing
  const imagePath = process.env.REACT_APP_CDN + '/_sec-uiux/jpg/';
  // state
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [gallerySingle, setGallerySingle] = useState(false);
  const [gallerySingleImage, setGallerySingleImage] = useState(null);

  let body = document.body;

  // Run once on first render
  useEffect(() => {
    window.scrollTo(0, 0);

    fetchMd(endpoint).then((ActualData) => {
      setData(ActualData.data);
      setError(null);
    })
      .catch((err) => {
        setError(err.message);
        setData(null);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  const gallerySingleOpen = (e) => {
    e.preventDefault();
    setGallerySingleImage(e.target.firstElementChild.src);
    setGallerySingle(!gallerySingle);
    body.classList.add("locked");
  }

  // const gallerySingleOpenKeyPress = (e) => {
  //   e.preventDefault();
  //   setGallerySingleImage(e.target.firstElementChild.src);
  //   setGallerySingle(!gallerySingle);
  //   body.classList.add("locked");
  // }

  const gallerySingleClose = (e) => {
    e.preventDefault();
    setGallerySingleImage('');
    setGallerySingle(!gallerySingle);
    body.classList.remove("locked");
  }

  const MdImage = props => {
    return (
      <TagFigure
        key={props.src}
        className={"uiux__media"}
        src={props.src}
        alt={props.alt}
        caption={props.alt}
        onClick={(e) => gallerySingleOpen(e)}
        onKeyPress={(e) => gallerySingleOpen(e)}
        tabIndex={0}
      />
    );
  };

  const renderers = { img: MdImage };

  if (loading) return <Loader />;

  if (error || !data) {
    return <p>There was an error loading your data!</p>;
  }

  return (
    <>
      <LayoutHeader data={props.header} />
      <div className="uiux">
        <ReactMarkdown
          children={data}
          transformImageUri={(src) => imagePath + src}
          components={renderers}
          remarkPlugins={[remarkUnwrapImages]}
        />
      </div>
      {gallerySingle ?
        <GallerySingle
          closeGallery={(e) => gallerySingleClose(e)}
          data={gallerySingleImage}
          figure={true}
        />
        : ''
      }
    </>
  )
}

export default SecureUiux;