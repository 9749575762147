import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import NavPrimary from './NavPrimary.js';
import NavSecondary from './NavSecondary.js';
import Button from './TagButton';
import SvgComponent from './SvgComponent';

import { isLoggedIn } from "../services/auth"
import { fetchData } from '../utilities/async';

const Header = ({ isDarkMode, setDarkMode }) => {
  const endpoint = '/json/kidkrazydotnet-nav.json';
  // state
  const [dataUrl, setDataUrl] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //routes
  const [linkPublic, setLinkPublic] = useState([]);
  const [linkSecure, setLinkSecure] = useState([]);
  const [linkSocial, setLinkSocial] = useState([]);

  const [header, setHeader] = useState('');
  const [trigger] = useState('10');
  const [isNav, setIsNav] = useState(false);
  const [isSettings, setIsSettings] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0);

    fetchData(endpoint).then((ActualData) => {
      setDataUrl(ActualData.data);
      // console.log('data', ActualData.data)
      setLinkPublic(ActualData.data[0].public)
      // console.log('public', ActualData.data[0].public)
      setLinkSecure(ActualData.data[1].secure)
      // console.log('public', ActualData.data[1].secure)
      setLinkSocial(ActualData.data[2].social)
      // console.log('public', ActualData.data[2].social)
      setError(null);
    })
      .catch((err) => {
        setError(err.message);
        setDataUrl(null);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [endpoint]);

  // set route matching
  let location = useLocation("/");
  // nav routes
  let linkAll = [...linkPublic, ...linkSecure];

  // nav effects
  useEffect(() => {

    const handleScroll = () => {
      const scrollTop = window.innerHeight;
      const scrollCurrent = window.pageYOffset;

      if (Math.abs(scrollTop - scrollCurrent) > trigger) {
        setHeader('header--solid');
      }

      if (Math.abs(scrollCurrent <= trigger)) {
        setHeader('');
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [trigger]);

  // listen for nav to lock body and toggle class
  useEffect(() => {
    let body = document.body;

    if (isNav) {
      body.classList.add("locked");
    }
    if (!isNav) {
      body.classList.remove("locked");
    }

  }, [isNav]);

  const toggleNav = () => {
    setIsNav(!isNav);

    if (isSettings) {
      setIsSettings(!isSettings)
    }
  }

  const toggleSettings = () => {
    setIsSettings(!isSettings);

    if (isNav) {
      setIsNav(!isNav)
    }
  }

  const resetNav = () => {
    setIsNav(false)
    setIsSettings(false)
  }

  const darkMode = (e) => {
    setDarkMode(!isDarkMode)
    // setIsSettings(!isSettings);
  }

  const Logo = (
    <Link to="/" className='brand' name='Home' aria-label='Home' onClick={resetNav}>
      <SvgComponent name="brand" svgClass="brand__svg" />
    </Link>
  );

  if (loading) return null;

  if (error || !dataUrl) {
    return <p>There was an error loading your data!</p>;
  }

  if (dataUrl.length > 0) {
    return (
      <header className={'header ' + header}>
        <Button
          buttonType='button'
          className={isNav ? "toggle toggle__menu toggle__menu--close" : "toggle toggle__menu"}
          name={isNav ? "navigation close" : "navigation open"}
          aria={isNav ? "navigation close" : "navigation open"}
          onClick={() => toggleNav()}
        />
        {isNav ?
          <NavPrimary
            isNav={isNav}
            setIsNav={setIsNav}
            toggleNav={toggleNav}
            links={!isLoggedIn() ? linkPublic : linkAll}
            social={linkSocial}
          />
          : ''}
        {location.pathname === '/' ? '' : Logo}
        <Button
          buttonType='button'
          className={isSettings ? "toggle toggle__settings toggle__settings--close" : "toggle toggle__settings"}
          name={isSettings ? "navigation close" : "navigation open"}
          aria={isSettings ? "navigation close" : "navigation open"}
          onClick={() => toggleSettings()}
        />
        {isSettings ?
          <NavSecondary
            isSettings={isSettings}
            toggleSettings={toggleSettings}
            isDarkMode={isDarkMode}
            darkMode={darkMode}
          />
          : ''}
      </header>
    )
  }
}

export default Header;