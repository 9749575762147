import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import TagButton from './TagButton';
import SvgComponent from './SvgComponent';

const Hero = (props) => {
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const heroArray = [
    {
      messageSmall: "welcome to",
      messageLarge: "kidkrazy.net",
      name: '',
      destination: '/',
      buttonClass: '',
      iconName: 'brand',
      iconClass: 'svg__brand'
    },
    {
      messageSmall: "I create",
      messageLarge: "digital media",
      name: "View Web Projects",
      destination: '/web',
      buttonClass: 'ghost',
      iconName: 'brand',
      iconClass: 'svg__brand'
    },
    {
      messageSmall: "I design",
      messageLarge: "products & brands",
      name: "View Design Projects",
      destination: '/design',
      buttonClass: 'ghost',
      iconName: 'brand',
      iconClass: 'svg__brand'
      
    },
    {
      messageSmall: "I create",
      messageLarge: "motion graphics",
      name: "View Video Projects",
      destination: '/videos',
      buttonClass: 'ghost',
      iconName: 'brand',
      iconClass: 'svg__brand'
    },
    {
      messageSmall: "I produce",
      messageLarge: "music & soundscapes",
      name: "Listen to Audio",
      external: 1,
      destination: 'https://sound.kidkrazy.net',
      buttonClass: 'ghost',
      iconName: 'brand',
      iconClass: 'svg__brand'
    },
    {
      messageSmall: "",
      messageLarge: "still curious?",
      name: "Learn About Me",
      destination: '/about',
      buttonClass: 'ghost',
      iconName: 'brand',
      iconClass: 'svg__brand'
    },
  ];

  const heroList = heroArray.map((hero, index) => {
    
    return (
      <div key={index}>
        <div className="slide--message">
          <span className="small">{hero.messageSmall} </span>
          <span className="large">{hero.messageLarge}</span>
          {hero.external
            ? <TagButton buttonType='anchor' destination={hero.destination} className={'btn__' + hero.buttonClass} icon={true} title={hero.name} />
            : <Link to={hero.destination} className={'btn__' + hero.buttonClass}><i />{hero.name}</Link>
          }
        </div>
        <SvgComponent name={hero.iconName} svgClass={hero.iconClass} />
      </div>
    )
  })

  return (
      <Carousel
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        swipeable={true}
        emulateTouch={true}
        swipeScrollTolerance={5}
        infiniteLoop={true}
        interval={5000}
        autoPlay={true}
        useKeyboardArrows
      >
        {heroList}
      </Carousel>
  )
}

export default Hero;