import React from "react";

// data
import Data from '../json/kidkrazydotnet-pageDescriptions.json';

// templates
import LayoutContainer from '../Layout/LayoutContainer';

// public views
import Home from '../views/Home';
import About from '../views/About';
import Design from '../views/Design';
import Videos from '../views/Videos';
import Web from '../views/Web';
import Projects from '../views/Projects';
import Contact from '../views/Contact';
// import Sound from '../views/Sound';
import Oops from '../views/Oops';
import Login from '../views/Login';

// data
const data = Data;
const dataWeb = data[0].web[0];
const dataDesign = data[1].design[0];
const dataVideo = data[2].video[0];
// const dataSound = data[3].audio[0];
const dataOops = data[4].oops[0];

// pathing
export const RoutesPublic = [
  {
    path: "/contact",
    element: <LayoutContainer cClass="contact"><Contact /></LayoutContainer>
  },
  {
    path: "/videos",
    element: <LayoutContainer cClass="projects"> <Videos header={dataVideo} /> </LayoutContainer>
  },
  {
    path: "/design",
    element: <LayoutContainer cClass="projects"> <Design header={dataDesign} /> </LayoutContainer>
  },
  {
    path: "/web",
    element: <LayoutContainer cClass="projects"> <Web header={dataWeb} /> </LayoutContainer>
  },
  {
    path: "/projects",
    element: <LayoutContainer cClass="projects"> <Projects header={dataWeb} /> </LayoutContainer>
  },
  // {
  //   path: "/audio",
  //   element: <LayoutContainer cClass="projects"> <Sound header={dataSound} /> </LayoutContainer>
  // },
  {
    path: "/about",
    element: <LayoutContainer cClass="about"> <About /> </LayoutContainer>
  },
  {
    path: "*",
    element: <LayoutContainer cClass="about"> <Oops header={dataOops} /> </LayoutContainer>
  },
  {
    path: "/login",
    element: <LayoutContainer cClass="login"> <Login /> </LayoutContainer>
  },
  {
    path: "/",
    element: <Home />
  }
];