import React from 'react';
import Button from './TagButton';

const ItemControls = (props) => {
    const { className, previous, close, next } = props;

    return (
        <div className={className}>
            {previous ?
                <Button
                    buttonType='button'
                    className='btn__back'
                    icon={true}
                    title='Previous'
                    aria-label='Previous'
                    onClick={previous}
                />
                : ''
            }
            {next ?
                <Button
                    buttonType='button'
                    className='btn__next'
                    icon={true}
                    title='Next'
                    aria-label='Next'
                    onClick={next}
                />
                : ''
            }
            <Button
                buttonType='button'
                className='btn__list'
                onClick={close}
                icon={true}
                title='Projects'
                aria-label='Projects List'
            />
        </div>
    )
}

export default ItemControls;