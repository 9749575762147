import React, { useState, useEffect, useReducer } from "react";

import { fetchData } from '../utilities/async';
import LayoutHeader from '../Layout/LayoutHeader';
import Loader from '../components/Loader';
import TagInput from '../components/TagInput';
import TagFigure from '../components/TagFigure';
import ItemControls from '../components/ItemControls';
import WebItem from '../components/WebItem';
import VideoItem from '../components/VideoItem';
import DesignItem from '../components/DesignItem';

const Projects = (props) => {
  // data
  const { REACT_APP_CDN } = process.env;
  const endpointWeb = '/json/kidkrazydotnet-web.json';
  const endpointVideo = '/json/kidkrazydotnet-video.json';
  const endpointDesign = '/json/kidkrazydotnet-design.json';
  //pathing
  const imagePath = REACT_APP_CDN + '/_images/thumbs/';
  const imagePathFull = REACT_APP_CDN + '/images/';
  const imagePathHero = REACT_APP_CDN + '/images/hero/';
  const videoPath = REACT_APP_CDN + '/videos/';

  // state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [dataUrlWeb, setDataUrlWeb] = useState([]);
  const [dataUrlVideo, setDataUrlVideo] = useState([]);
  const [dataUrlDesign, setDataUrlDesign] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [details, setDetails] = useState(false);
  const [viewList, setViewList] = useState(true);
  const [selectedProject, setSelectedProject] = useState(0);
  const currentView = dataAll[selectedProject];

  // Run once on first render
  useEffect(() => {
    window.scrollTo(0, 0);
    Promise.all([
      fetchData(endpointWeb),
      fetchData(endpointDesign),
      fetchData(endpointVideo)
    ])
      .then(([web, design, video]) => {
        setDataUrlWeb(web.data)
        setDataUrlDesign(design.data)
        setDataUrlVideo(video.data)
        // console.group('web: ', web, 'design:', design, 'video:', video)
        let all = [].concat(web.data, design.data, video.data)
        // console.log('dataAll:', all)
        setDataAll(all)
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  const detailOpen = (index) => {
    setDetails(true);
    setSelectedProject(index);
  }

  const detailClose = () => {
    setDetails(!setDetails);
    setSearchTerm('')
  }

  const detailPrev = () => {
    if (selectedProject === 0) {
      detailClose();
    }
    setSelectedProject(selectedProject - 1);
    window.scrollTo(0, 0);
  }

  const detailNext = () => {
    const projectTotal = dataAll.length;

    if (selectedProject === projectTotal - 1) {
      detailClose();
    }
    setSelectedProject(selectedProject + 1);
    window.scrollTo(0, 0);
  }

  // sort by type && date desc
  const sorted = dataAll.sort((a, b) => {
    if (a.type === b.type) {
      // type is only important when date is the same
      return b.date - a.date;
    }
    return a.date > b.date ? -1 : 1;
  });
  // console.log('sorted', dataAll.sort((a, b) => b.date - a.date));

  if (loading) return <Loader />;

  if (error || !sorted) {
    return <p>There was an error loading your data!</p>;
  }

  if (details && sorted.length > 0) {
    return (
      <>
        {currentView.type === 'web' ?
          <WebItem
            key={selectedProject}
            className='projects__detail'
            hero={imagePathHero + currentView.type + '/' + currentView.hero}
            title={currentView.title}
            summary={currentView.summary}
            summaryFull={currentView.summaryFull}
            role={currentView.role}
            keywords={currentView.keywords}
            date={currentView.date}
            link={currentView.link}
            screenshotsPath={imagePathFull + currentView.type + '/'}
            screenshots={currentView.screenshots}
          />
          : ''
        }

        {currentView.type === 'video' ?
          <VideoItem
            key={selectedProject}
            className='projects__detail'
            title={currentView.title}
            summary={currentView.summary}
            summaryFull={currentView.summaryFull}
            role={currentView.role}
            keywords={currentView.keywords}
            date={currentView.date}
            runtime={currentView.runtime}
            poster={currentView.poster !== null ? imagePath + currentView.type + '/' + currentView.poster : 'placeholder'}
            source={currentView.file !== null ? videoPath + currentView.file : ''}
            src={currentView.link !== null ? currentView.link : ''}
            type={currentView.srcType !== null ? currentView.srcType : ''}
          />
          : ''
        }

        {currentView.type === 'design' ?
          <DesignItem
            key={selectedProject}
            className='projects__detail'
            hero={imagePathHero + currentView.type + '/' + currentView.hero}
            title={currentView.title}
            summary={currentView.summary}
            summaryFull={currentView.summaryFull}
            role={currentView.role}
            keywords={currentView.keywords}
            date={currentView.date}
            screenshotsPath={imagePathFull + currentView.type + '/'}
            screenshots={currentView.screenshots}
          />
          : ''
        }
          <ItemControls
            className='projects__controls '
            close={() => detailClose()}
            next={searchTerm === '' ? () => detailNext() : ''}
            previous={searchTerm === '' ? () => detailPrev() : ''}
          />
      </>
    )
  }

  const ProjectList = sorted.reduce((acc, item, index) => {
    if (searchTerm == '') {
      acc.push({ index });
    }
    else if (item.title.toLowerCase().includes(searchTerm.toLowerCase())) {
      acc.push({ index });
    }
    else if (item.date.toLowerCase().includes(searchTerm.toLowerCase())) {
      acc.push({ index });
    }
    else if (item.keywords.toLowerCase().includes(searchTerm.toLowerCase())) {
      acc.push({ index });
    }
    else if (item.role.toLowerCase().includes(searchTerm.toLowerCase())) {
      acc.push({ index });
    }
    else if (item.type.toLowerCase().includes(searchTerm.toLowerCase())) {
      acc.push({ index });
    }
    return acc;
  }, []).map(({ index }) => {
    const item = sorted[index];

    return (
      <TagFigure
        key={index}
        tabIndex="0"
        className={`web__thumb ${item.featured ? ' ' : ''}`}
        src={item.featured ? imagePathHero + item.type + '/' + item.hero : imagePath + item.type + '/' + item.hero}
        onClick={(e) => detailOpen(index, e)}
        caption={`${item.title} \n\n ${item.date}, ${item.type}, click: ${index}`}
      />
    )
  });

  return (
    <>
      <LayoutHeader data={props.header} />
      <TagInput
        inputType={'input'}
        id={'search'}
        className={'search'}
        label={'search'}
        type={'search'}
        onChange={(event) => setSearchTerm(event.target.value)}
        placeholder='Search by title, year, type, role, keyword...'
      >
        <i />
      </TagInput>
      <div className={viewList ? 'projects--grid' : 'projects--list'}>
        {[ProjectList]}
      </div>
    </>
  );
};

export default Projects;