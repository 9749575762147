import React, { useState, useEffect } from "react";
import SvgComponent from './../components/SvgComponent';

const PageHeader = ({ data, children }) => {
  const [appearance, setAppearance] = useState('');

  const unset = (timer) => {
    setAppearance('')
  }

  useEffect(() => {
    setAppearance('reveal-animation')
    const timer = setTimeout(unset, 2000);
    
    return () => {
      clearTimeout(timer)
    }
  }, [data]);

  return (
    <header className={appearance}>
      <h1 className="heading" tabIndex="0">{data.heading}</h1>
      <h2 className="subheading">{data.subheading}</h2>
      <hr className="divider" />
      {children}
      <p className="overview">{data.overview}</p>
      <SvgComponent name={data.iconName} svgClass={data.iconClass} />
    </header>
  )
}

export default PageHeader;