import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from './TagButton';
import GallerySingle from '../components/GallerySingle';

const WebItem = (props) => {
  const { className, onClick, hero, title, summary, summaryFull, role, keywords, date, link, screenshots, screenshotsPath } = props;
  const [gallerySingle, setGallerySingle] = useState(false);
  const [gallerySingleImage, setGallerySingleImage] = useState(null);

  let body = document.body;

  // Run once on first render
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const gallerySingleOpen = (e) => {
    e.preventDefault();
    setGallerySingleImage(e.target.src);
    setGallerySingle(!gallerySingle);
    body.classList.add("locked");
  }

  const gallerySingleClose = (e, prev) => {
    e.preventDefault();
    setGallerySingleImage(prev);
    setGallerySingle(!gallerySingle);
    body.classList.remove("locked");
  }

  const ImageList = screenshots.map((item, index) => {
    const singleImage = screenshotsPath + item;

    return (
      <img
        key={index}
        tabIndex="0"
        src={singleImage}
        data-src={singleImage}
        alt={item}
        loading="lazy"
        onClick={(e) => gallerySingleOpen(e)}
        onKeyPress={(e) => gallerySingleOpen(e)}
      />
    )
  })

  return (
    <>
      <div
        className={className}
        onClick={onClick}
      >
        {hero
          ? <picture
            className={className + '--media'}
          >
            <source
              media=""
              srcSet={hero}
            />
            <img
              width=""
              height=""
              src={hero}
              data-src={hero}
              alt=""
              loading="eager"
            />
          </picture>
          : ''
        }
        {title ? <h2>{title}</h2> : ''}
        {summary ? <h5>{summary}</h5> : ''}
        <hr className="divider" />
        {summaryFull ? <p>{summaryFull}</p> : ''}
        <hr className="divider" />
        {role ? <p><strong>Role:</strong> {role}</p> : ''}
        {keywords ? <p><strong>Keywords:</strong> {keywords}</p> : ''}
        {date ? <p><strong>Date:</strong> {date}</p> : ''}
        <p className="spacer"> </p>
        {link ?
          <Button
            buttonType='anchor'
            className="btn__link"
            icon={true}
            destination={'http://' + link}
            target='_blank'
            rel="norefferer"
            title='View Site'
          />
          : ''
        }
        {screenshots ? <div className="screens"> {ImageList} </div> : ''}
      </div>
      {gallerySingle
        ? <GallerySingle
          data={gallerySingleImage}
          destination={'design/'}
          closeGallery={(e) => gallerySingleClose(e)}
        />
        : ''
      }
    </>
  )
}

WebItem.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.string,
  // mediaClick: PropTypes.func,
  hero: PropTypes.string,
  title: PropTypes.string,
  summary: PropTypes.string,
  summaryFull: PropTypes.string,
  role: PropTypes.string,
  keywords: PropTypes.string,
  date: PropTypes.string,
  screenshots: PropTypes.array,
  screenshotsPath: PropTypes.string,
};

export default WebItem;