import React, { useEffect } from "react";
import Hero from '../components/Hero';

const Home = (props) => {
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="home">
      <Hero />
    </section>
  )
}

export default Home;