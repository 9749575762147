import React from "react";
import { NavLink } from "react-router-dom";

import { isLoggedIn, logout } from "../services/auth"
import Button from './TagButton';

const NavSecondary = ({ isSettings, toggleSettings, isDarkMode, darkMode }) => {
  
  const handleClick = () => {
    if (isSettings) {
      toggleSettings()
    }

    if (isLoggedIn) {
      logout()
    }
  }

  const Mode = (
    < li >
      <Button
        buttonType='button'
        className={`mode ${!isDarkMode ? ' mode__light' : ' mode__dark'}`}
        title={`Toggle ${!isDarkMode ? ' Dark ' : ' Light '} Mode`}
        aria={`Toggle ${!isDarkMode ? ' Dark ' : ' Light '} Mode`}
        onClick={() => darkMode()}
        icon={true}
      />
    </li >
  )

  const LogUser = (
    <li >
      <NavLink
        to={!isLoggedIn() ? '/login' : '/'}
        className={({ isActive }) => isActive ? 'btn--active' : ''}
        onClick={handleClick}
        tabIndex="0"
      >
        {!isLoggedIn() ? 'Login' : 'Logout'}
      </NavLink>
    </li>
  )

  return (
    <nav className="nav nav--visible nav__settings">
      <ul className="nav__settings--container">
        {LogUser}
        {Mode}
      </ul>
    </nav>
  )
}

export default NavSecondary;