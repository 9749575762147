import React from "react";

// data
import Data from '../json/kidkrazydotnet-pageDescriptions.json';

// templates
import LayoutContainer from '../Layout/LayoutContainer';

// protected views
// import Greeting from '../views/Greeting';
import SecureUiux from '../views/SecureUiux';

// data
const data = Data;
// const dataGreeting = data[5].uiux[0];
const dataUiUx = data[6].uiux[0];

// pathing
export const RoutesSecure = [
  // {
  //   path: "/greeting",
  //   element: <LayoutContainer cClass="about"><Greeting header={dataGreeting} /></LayoutContainer>
  // },
  {
    path: "/uiux",
    element: <LayoutContainer cClass="about"><SecureUiux header={dataUiUx} /></LayoutContainer>
  }
];