import React from "react";
import { NavLink, Link } from "react-router-dom";
import TagButton from './TagButton';

import Social from './Social.js';

const NavPrimary = ({ isNav, toggleNav, links, social }) => {

  const Copyright = (
    <span className="copyright">©2022 kidkrazy. All rights reserved.</span>
  );

  const NavLinks = links.map((item, i) =>
    <li key={i}>
      {item.external
        ? <TagButton
          buttonType='anchor'
          destination={item.destination}
          title={item.name}
        />
        : <NavLink
          to={'/' + item.destination}
          className={({ isActive }) => isActive ? 'btn--active' : ''}
          onClick={isNav ? () => toggleNav() : ''}
          tabIndex="0"
        >
          {item.name}
        </NavLink>
      }
    </li>
  );

  return (
    <nav className="nav nav--visible nav__menu">
      <ul className="nav__menu--container">
        <NavLink
          to={'/projects'}
          className={({ isActive }) => isActive ? 'btn--active' : ''}
          onClick={isNav ? () => toggleNav() : ''}
          tabIndex="0"
        >
          projects
        </NavLink>
        {NavLinks}
      </ul>
      <ul className="social">
        <Social links={social} />
      </ul>
      {Copyright}
    </nav>
  )
}

export default NavPrimary;